<template>
  <div class="addComplain bgFFF commonF padding20 paddingLR20 paddingT20">
    <titleBox title="客诉信息">
      <el-form
        ref="form"
        :inline="true"
        label-position="right"
        :model="formInline"
        class="demo-form-inline"
        :rules="rules"
        label-width="130px"
      >
        <el-form-item label="客诉来源" prop="dataSource">
          <el-select
            v-model="formInline.dataSource"
            @change="dataSourceChange"
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option
              v-for="item in customerDataSourceList"
              :key="item.value"
              :label="item.desc"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Work_Order_Number')" prop="orderNo">
          <el-input
            v-model.trim="formInline.orderNo"
            placeholder="请输入内容"
            style="width: 200px"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Work_order_type')" prop="orderType">
          <el-select v-model="formInline.orderType" placeholder="请选择" style="width: 200px">
            <el-option
              v-for="item in orderTypeList"
              :key="item.value"
              :label="item.desc"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="被反映单位" prop="questionCompany">
          <el-input
            v-model.trim="formInline.questionCompany"
            maxlength="50"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="承办单位" prop="executeCompany">
          <el-input
            v-model.trim="formInline.executeCompany"
            maxlength="50"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名" prop="name">
          <el-input
            v-model.trim="formInline.name"
            placeholder="请输入内容"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model.trim="formInline.phone"
            placeholder="请输入内容"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否重复投诉" prop="repeat">
          <el-select v-model="formInline.repeat" placeholder="请选择" style="width: 200px">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="平台派单时间"
          prop="platformTime"
          v-show="formInline.dataSource === 1 || formInline.dataSource === 2"
        >
          <el-date-picker
            @change="platformTimeChange"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            v-model="formInline.platformTime"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="城管委派单时间" prop="urbanTime" v-show="formInline.dataSource === 2">
          <el-date-picker
            style="width: 200px"
            v-model="formInline.urbanTime"
            value-format="yyyy-MM-dd"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="提醒时间" prop="remindTime">
          <el-date-picker
            style="width: 200px"
            value-format="yyyy-MM-dd"
            v-model="formInline.remindTime"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="截止时间"
          prop="cutoffTime"
          v-show="formInline.dataSource === 1 || formInline.dataSource === 2"
        >
          <el-date-picker
            v-model="formInline.cutoffTime"
            value-format="yyyy-MM-dd"
            type="date"
            style="width: 200px"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <br />
        <el-form-item label="投诉具体内容" prop="content">
          <el-input
            type="textarea"
            v-model="formInline.content"
            placeholder="请输入内容"
            style="width: 500px"
            maxlength="500"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button type="primary" @click="save('/complainManage')" :loading="loading"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-button type="primary" @click="save()" :loading="loading">保存并处理</el-button>
          <el-button type="info" @click="cancel()" :loading="loading">取消</el-button>
        </el-form-item>
      </el-form>
    </titleBox>
  </div>
</template>
<script>
import titleBox from "./components/title";
import { dateFormat } from "@/common/js/public";
export default {
  components: {
    titleBox,
  },
  data() {
    let rule = (message) => {
      return (rule, value, callback) => {
        let flag = true;
        if (message === "请输入提醒时间" && !value) {
          flag = false;
        } else if (
          message === "请输入截止时间" &&
          (this.formInline.dataSource === 1 || this.formInline.dataSource === 2) &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请输入城管委派单时间" &&
          this.formInline.dataSource === 2 &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请输入平台派单时间" &&
          (this.formInline.dataSource === 1 || this.formInline.dataSource === 2) &&
          !value
        ) {
          flag = false;
        }
        if (flag) {
          return callback();
        } else {
          return callback(new Error(message));
        }
      };
    };
    let phone = (rule, value, callback) => {
      if (/^[0-9]{1,}$/.test(value)) {
        return callback();
      } else {
        return callback(new Error("请输入正确的电话号码"));
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      complaintStatusList: [],
      orderTypeList: [],
      customerDataSourceList: [],
      loading: false,
      tableData: [],
      formInline: {
        orderNo: "",
        dataSource: 1,
        orderType: 1,
        questionCompany: "",
        executeCompany: "",
        name: "",
        phone: "",
        repeat: "0",
        platformTime: "",
        urbanTime: "",
        remindTime: "",
        cutoffTime: "",
        content: "",
      },
      rules: {
        dataSource: [
          {
            required: true,
            message: "请选择客诉来源",
            trigger: "blur",
          },
        ],
        orderType: [
          {
            required: true,
            message: "请选择工单类型",
            trigger: "blur",
          },
        ],
        orderNo: [
          {
            required: true,
            message: "请输入工单编号",
            trigger: "blur",
          },
        ],
        executeCompany: [
          {
            required: false,
            message: "请输入承办单位",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { validator: phone, required: true, trigger: "blur" },
          {
            required: true,
            message: "请输入投诉人电话",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入投诉具体内容",
            trigger: "blur",
          },
        ],
        remindTime: [{ validator: rule("请输入提醒时间"), required: true, trigger: "blur" }],
        urbanTime: [
          {
            validator: rule("请输入城管委派单时间"),
            required: true,
            trigger: "blur",
          },
        ],
        cutoffTime: [{ validator: rule("请输入截止时间"), required: true, trigger: "blur" }],
        platformTime: [
          {
            validator: rule("请输入平台派单时间"),
            required: true,
            trigger: "blur",
          },
        ],
        repeat: [
          {
            required: true,
            message: "请选择是否重复投诉",
            trigger: "blur",
          },
        ],
        questionCompany: [
          {
            message: "请输入被反映单位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    dataSourceChange(value) {
      if (value === 3 || value === 4) {
        this.rules.orderNo = [
          {
            required: false,
            message: "请输入工单编号",
            trigger: "blur",
          },
        ];
      } else {
        this.rules.orderNo = [
          {
            required: true,
            message: "请输入工单编号",
            trigger: "blur",
          },
        ];
      }
    },
    platformTimeChange() {
      this.formInline.remindTime = dateFormat(
        new Date(new Date(this.formInline.platformTime).getTime() + 3 * 86400000),
        "yyyy-MM-dd"
      );
    },
    getAddOption() {
      this.$axios.get("/acb/2.0/customerComplaint/getAddOption").then((res) => {
        this.complaintStatusList = res.value.customerStatus;
        this.orderTypeList = res.value.customerOrderType;
        this.customerDataSourceList = res.value.customerDataSource;
      });
    },
    save(url) {
      if (
        this.formInline.cutoffTime &&
        this.formInline.remindTime &&
        new Date(this.formInline.cutoffTime) < new Date(this.formInline.remindTime)
      ) {
        this.$alert("截止时间不能小于提醒时间");
        return;
      }
      if (
        this.formInline.platformTime &&
        this.formInline.cutoffTime &&
        new Date(this.formInline.cutoffTime) < new Date(this.formInline.platformTime)
      ) {
        this.$alert("截止时间不能小于平台派单时间");
        return;
      }
      if (
        this.formInline.urbanTime &&
        this.formInline.cutoffTime &&
        new Date(this.formInline.cutoffTime) < new Date(this.formInline.urbanTime)
      ) {
        this.$alert("截止时间不能小于城管委派单时间");
        return;
      }
      if (
        this.formInline.urbanTime &&
        this.formInline.remindTime &&
        new Date(this.formInline.remindTime) < new Date(this.formInline.urbanTime)
      ) {
        this.$alert("提醒时间不能小于城管委派单时间");
        return;
      }
      if (
        this.formInline.platformTime &&
        this.formInline.remindTime &&
        new Date(this.formInline.remindTime) < new Date(this.formInline.platformTime)
      ) {
        this.$alert("提醒时间不能小于平台派单时间");
        return;
      }
      this.$refs.form.validate((v) => {
        if (v) {
          this.$axios
            .post("/acb/2.0/customerComplaint/add", {
              data: {
                ...this.formInline,
              },
            })
            .then((res) => {
              this.loading = false;
              if (res.state == 0) {
                if (url) {
                  this.$router.push(url);
                } else {
                  this.$router.push({
                    path: "/disposeComplain",
                    query: {
                      complaintId: res.value,
                    },
                  });
                }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
    cancel() {
      this.$router.go("-1");
    },
  },
  created() {
    this.getAddOption();
  },
};
</script>
